
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { ROUTE } from '@elendi/util-endpoints';
import { Skeleton } from '@elendi/feature-authentication/ui';
import { defaultSignIn } from '@elendi/feature-authentication/utils';
const Login = () => {
    const router = useRouter();
    const { status } = useSession();
    const prefill = String(router.query['prefill']);
    if (status === 'loading')
        return <Skeleton />;
    if (status === 'unauthenticated')
        defaultSignIn({ prefill, callbackUrl: ROUTE.index });
    if (status === 'authenticated')
        router.push(ROUTE.index);
    return <Skeleton />;
};
export default Login;

    async function __Next_Translate__getStaticProps__19320080ca4__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19320080ca4__ as getStaticProps }
  